import React, { useEffect, useState } from "react";
import Invoice from "./Invoice";
import CustomizationProductInvoice from "./CustomizationProductInvoice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getspecificSale } from "../../../store/AsyncMethods/SalesMethod";
import {
  getAllVarients,
  getProducts,
} from "../../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_SALES } from "../../../store/Types/SalesTypes";
import { useReactToPrint } from "react-to-print";

export default function SaleInvoiceMain() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allVarients, products } = useSelector(
    (state) => state.ProductReducer
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const { saleId } = useParams();

  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
    }
  }, [saleId, dispatch]);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getAllVarients());
  }, [dispatch]);

  useEffect(() => {
    if (specificSale && allVarients.length > 0 && products.length > 0) {
      setIsDataLoaded(true);
    }
  }, [specificSale, allVarients, products]);

  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
    onAfterPrint: () => {
      dispatch({ type: RESET_SPECIFIC_SALES });
      navigate(user?.role_id == 1 ? "/sales" : "/sales-sales");
    },
  });

  useEffect(() => {
    if (isDataLoaded) {
      const timer = setTimeout(() => {
        handlePrint();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isDataLoaded, handlePrint]);

  return (
    <div>
      <div className="bg-white mx-auto">
        <div id="receipt-component">
          <div className="page-break">
            <Invoice />
          </div>
          <div className="page-break">
            {/* <CustomizationProductInvoice /> */}
          </div>
        </div>
      </div>
      <style>
        {`
          @media print {
            .page-break {
              page-break-after: always;
              width: 80mm;
              margin: 0 auto;
            }
          }
        `}
      </style>
    </div>
  );
}
