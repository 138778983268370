import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import {
  getAllVarients,
  getProducts,
  getspecificVarientsByProduct,
} from "../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_PRODUCT } from "../../store/Types/ProductTypes";
import AddSalesForm from "./AddSalesForm";
import { getCustomers } from "../../store/AsyncMethods/CustomerMethod";
import AddSalesProductForm from "./AddSalesProductForm";
import AddSalesProductTable from "./AddSalesProductTable";
import SalesCalculation from "./SalesCalculation";
import {
  addSale,
  getSales,
  getspecificSale,
  updateSales,
} from "../../store/AsyncMethods/SalesMethod";
import { TabMenu } from "primereact/tabmenu";
import CustomizationProduct from "./CustomizationProduct";
import { RESET_SPECIFIC_SALES } from "../../store/Types/SalesTypes";
import AddCustomerSize from "./AddCustomerSize";
import { getsCategory } from "../../store/AsyncMethods/ScatMethod";
import { getCustomizationProduct } from "../../store/AsyncMethods/SetupMethods";

export default function AddSales() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPosted, setIsPosted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const items = [
    { label: "Sales", icon: "pi pi-wrench" },
    { label: "Customer Size", icon: "pi pi-users" },
    { label: "Customization Products", icon: "pi pi-pencil" },
  ];
  const { saleId } = useParams();

  const { error, user } = useSelector((state) => state.AuthReducer);
  const { specificVarients } = useSelector((state) => state.ProductReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allCustomizationProduct, loading } = useSelector(
    (state) => state.SetupReducer
  );

  const [validateVarient, setValidateVarient] = useState(true);
  const [isRefund, setIsRefund] = useState(false);
  const [selectedCustomizationProduct, setSelectedCustomizationProduct] =
    useState(null);
  const [customizationProducts, setCustomizationProducts] = useState([]);
  const [currentProductQuantity, setCurrentProductQuantity] = useState(0);
  // const [customizationProducts, setCustomizationProducts] = useState([]);
  const [slectedProductQuantity, setSelectedProductQuantity] = useState(0);

  const [editingProduct, setEditingProduct] = useState(null);

  const editProduct = (product) => {
    setEditingProduct(product);
  };


  const formik = useFormik({
    initialValues: {
      name: "",
      salesMan: "",
      phone: "",
      customerId: 0,
      customerSizeId: 0,
      orderBookNo: "",
      sCategoryId: 0,
      customerSize: {},
      customerSizes: [],
      customers: [],
      status: true,
      saleStatus: "invoice",
      saleDiscount: 0,
      products: [],
      userId: user.id,
      totalPurchase: 0,
      totalSales: 0,
      totalDiscount: 0,
      totalSaleDiscount: 0,
      vatPer: 0,
      posted: 0,
      unit: "",
      color: "N/A",
      design: "N/A",
      vatAmount: 0,
      totalAmount: 0,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        saleDiscount: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 0,
        total: 0,
        discount_value: 0,
        is_discount_percentage: true,
      },
      customizationProducts: [],
      allCustomizationProducts: [],
      customizationProductsTotal: 0,
      trialDate: "",
      deliveryDate: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Customer Name Required"),
      salesMan: Yup.string().required("Sales Man Name Required"),
      saleStatus: Yup.string().required("Sale Status Required"),
      status: Yup.boolean().required("Status Required"),
      product: validateVarient
      ? Yup.object().shape({
          product: Yup.number().required("Product Required"),
          varient: Yup.number(),
          purchasePrice: Yup.number().required("Purchase Price is required"),
          salePrice: Yup.number().required("Sale Price is required"),
          discount: Yup.number().required("Discount is required"),
          quantity: Yup.number()
            .required("Quantity is required")
            .positive("Quantity should be greater than 0")
            .test(
              "max-quantity",
              "Quantity should be less than or equal to current product quantity.",
              function (value) {
                const { saleStatus } = this.parent;
                return saleStatus !== "invoice" || (value ?? 0) <= currentProductQuantity;
              }
            ),
          total: Yup.number().required("Total is required"),
          discount_value: Yup.number().required("Discount is required"),
          is_discount_percentage: Yup.boolean(),
        })
      : Yup.object(),
    }),
    onSubmit: async (data) => {
      console.log(data);
      if (specificSale) {
        dispatch(updateSales(data, saleId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_SALES });
            dispatch(getSales());
            navigate(user?.role_id == 1 ? "/sales" : "/sales-sales");
          }
        });
      } else {
        console.log(data);
        dispatch(addSale(data)).then((success) => {
          if (success.success) {
            formik.resetForm();
            dispatch(getSales());
            navigate(
              user?.role_id == 1
                ? "/invoice-sales/" + success.insertSaleId
                : "/invoice-sales-sales/" + success.insertSaleId
            );
          }
        });
      }
    },
  });

  const handleAddCustomizationProduct = () => {
    debugger
    if (
      selectedCustomizationProduct &&
      !formik.values.customizationProducts.find(
        (item) => item.id === selectedCustomizationProduct.id
      )
    ) {
      const newProduct = {
        type: selectedCustomizationProduct.type,
        id: selectedCustomizationProduct.id,
        price: selectedCustomizationProduct.price,
        quantity: 1,
        discount: 0,
      };
      formik.setFieldValue("customizationProducts", [
        ...formik.values.customizationProducts,
        newProduct,
      ]);
      setSelectedCustomizationProduct(null);
    }
  };

  const handleRemoveCustomizationProduct = (productType) => {
    const updatedProducts = formik.values.customizationProducts.filter(
      (item) => item.type !== productType
    );
    formik.setFieldValue("customizationProducts", updatedProducts);
  };

  const handleCustomizationProductChange = (index, field, value) => {
    const updatedProducts = [...formik.values.customizationProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };
    formik.setFieldValue("customizationProducts", updatedProducts);
  };

  const renderCustomizationDropdown = () => {
    const availableProducts = allCustomizationProduct.filter(
      (item) =>
        !formik.values.customizationProducts.some((prod) => prod.id === item.id)
    );

    return (
      <div className="flex items-center mb-4">
        <Dropdown
          value={selectedCustomizationProduct}
          options={availableProducts}
          onChange={(e) => setSelectedCustomizationProduct(e.value)}
          optionLabel="type"
          placeholder="Select Customization Product"
          className="w-full"
        />
        <Calendar
          value={
            formik.values.trialDate ? new Date(formik.values.trialDate) : null
          }
          onChange={(e) => formik.setFieldValue("trialDate", e.value)}
          placeholder="Trial Date"
          className="mr-2 pl-2"
          dateFormat="yy-mm-dd"
        />
        <Calendar
          value={
            formik.values.deliveryDate
              ? new Date(formik.values.deliveryDate)
              : null
          }
          onChange={(e) => formik.setFieldValue("deliveryDate", e.value)}
          placeholder="Delivery Date"
          className="mr-2"
          dateFormat="yy-mm-dd"
        />
        <Button
          label="Add"
          icon="pi pi-plus"
          className="ml-2 p-button-success"
          onClick={handleAddCustomizationProduct}
          disabled={!selectedCustomizationProduct}
        />
      </div>
    );
  };

  const renderCustomizationSelected = () => {
    if (formik.values.customizationProducts.length === 0) {
      return (
        <div className="text-gray-500 italic">
          No customization products selected.
        </div>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Type</th>
              <th className="py-2 px-4 text-left">Price</th>
              <th className="py-2 px-4 text-left">Quantity</th>
              <th className="py-2 px-4 text-left">Discount</th>
              <th className="py-2 px-4 text-left">Total</th>
              <th className="py-2 px-4 text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.customizationProducts.map((product, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="py-2 px-4">{product.type}</td>
                <td className="py-2 px-4">
                  <InputNumber
                    value={product.price}
                    onValueChange={(e) =>
                      handleCustomizationProductChange(index, "price", e.value)
                    }
                    prefix="Rs "
                    disabled
                  />
                </td>
                <td className="py-2 px-4">
                  <InputNumber
                    value={product.quantity}
                    onValueChange={(e) =>
                      handleCustomizationProductChange(
                        index,
                        "quantity",
                        e.value
                      )
                    }
                    showButtons
                    buttonLayout="horizontal"
                    step={1}
                    min={1}
                  />
                </td>
                <td className="py-2 px-4">
                  <InputNumber
                    value={product.discount}
                    onValueChange={(e) =>
                      handleCustomizationProductChange(
                        index,
                        "discount",
                        e.value
                      )
                    }
                    prefix={product.is_discount_percentage ? "" : "Rs "}
                  />
                </td>
                <td className="py-2 px-4">
                  <span className="font-bold">
                    Rs {calculateTotal(product).toFixed(2)}
                  </span>
                </td>
                <td className="py-2 px-4 text-right">
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger p-button-sm"
                    onClick={() => {
                      const updatedProducts =
                        formik.values.customizationProducts.filter(
                          (_, i) => i !== index
                        );
                      formik.setFieldValue(
                        "customizationProducts",
                        updatedProducts
                      );
                    }}
                    tooltip="Remove"
                    tooltipOptions={{ position: "left" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    const total = formik.values.customizationProducts.reduce(
      (sum, product) => sum + calculateTotal(product),
      0
    );
    formik.setFieldValue("customizationProductsTotal", total);
  }, [formik.values.customizationProducts]);

  const calculateTotal = (product) => {
    const subtotal = product.price * product.quantity;
    const discountAmount = product.is_discount_percentage
      ? subtotal * (product.discount / 100)
      : product.discount;
    return subtotal - discountAmount;
  };

  const ModalContent = () => (
    <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-6xl max-h-[95vh] overflow-auto flex flex-col">
      <h2 className="text-2xl font-semibold mb-6">Customization Products</h2>
      <div className="flex-grow mb-6">
        <p className="mb-4">Select customization products for this sale:</p>
        {renderCustomizationDropdown()}
        <div className="mt-4">
          <h3 className="text-lg font-medium mb-2">Selected Products:</h3>
          {renderCustomizationSelected()}
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="text-xl font-bold">
          Total: Rs{" "}
          {formik.values.customizationProducts
            .reduce((sum, product) => sum + calculateTotal(product), 0)
            .toFixed(2)}
        </div>
        <div className="flex space-x-4">
          <Button
            label="Cancel"
            className="p-button-secondary"
            onClick={handleCloseModal}
          />
          <Button
            label="Confirm Submit"
            className="p-button-primary"
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </div>
  );



  useEffect(() => {
    console.log(specificSale);
    const formatDate = (date) => {
      if (!date) return "";
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`; // Output in the format "YYYY-MM-DD"
    };

    const trialDate = formatDate(specificSale?.trial_date);
    const deliveryDate = formatDate(specificSale?.delivery_date);
    if (specificSale) {
      setIsPosted(specificSale?.is_posted === 1 ? true : false);
      formik.setValues({
        name: specificSale.customer_name || "",
        salesMan: specificSale.sales_man || "",
        customerId: specificSale.customer_id || 1,
        customerSizeId: specificSale.customer_id || 1,
        sCategoryId: "",
        customerSizes: specificSale.customerSizes || [],
        customers: specificSale.customers || [],
        saleStatus: specificSale.status || "invoice",
        phone: specificSale.customer_phone || "03",
        totalPurchase: specificSale.total_purchase || 0,
        status: specificSale.is_active === 1 ? true : false,
        saleDiscount: specificSale.sale_discount || 0,
        totalSales: specificSale.total_sales || 0,
        totalDiscount: specificSale.total_discount || 0,
        totalSaleDiscount: specificSale?.total_sale_discount || 0,
        vatPer: specificSale.vat_per || 0,
        vatAmount: specificSale.vat_amount || 0,
        totalAmount: specificSale.total_amount || 0,
        userId: user.id,
        posted: specificSale.is_posted === 1 ? true : false || 0,
        unit: 0,
        products: specificSale.products || [],
        product: {
          purchasePrice: 0,
          salePrice: 0,
          saleDiscount: 0,
          product: "",
          varient: "",
          discount: 0,
          quantity: 0,
          total: 0,
          discount_value: 0,
          is_discount_percentage: true,
        },
        customizationProducts: specificSale.customizationProducts || [],
        allCustomizationProducts: [],
        orderBookNo: specificSale.order_book_no || "",
        customizationProductsTotal:
          specificSale.customization_products_total || 0,
        trialDate: trialDate,
        deliveryDate: deliveryDate,
      });
    }
  }, [specificSale]);

  useEffect(() => {
    if (formik.values.saleStatus === "refund") {
      formik.setValues({
        ...formik.values,
        totalSales: -Math.abs(formik.values.totalSales),
        totalDiscount: -Math.abs(formik.values.totalDiscount),
        totalAmount: -Math.abs(formik.values.totalAmount),
      });
    } else {
      formik.setValues({
        ...formik.values,
        totalSales: Math.abs(formik.values.totalSales),
        totalDiscount: Math.abs(formik.values.totalDiscount),
        totalAmount: Math.abs(formik.values.totalAmount),
      });
    }
  }, [formik.values.saleStatus]);

  const removeProduct = (productId) => {
    formik.setValues((prevValues) => {
      const updatedProduct = prevValues.products.filter(
        (product) => product.id !== productId
      );
      return { ...prevValues, products: updatedProduct };
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (formik.values.product?.product) {
      dispatch(getspecificVarientsByProduct(formik.values.product?.product));
    }
  }, [formik.values.product?.product]);

  useEffect(() => {
    if (user) {
      dispatch(getCustomers());
      dispatch(getProducts());
      dispatch(getAllVarients());
      dispatch(getCustomizationProduct());
    }
    dispatch(getsCategory());
  }, []);

  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
      setValidateVarient(false);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_SPECIFIC_SALES });
    formik.resetForm();
  }, [location.pathname]);

  useEffect(() => {
    if (formik.values.product.purchasePrice && formik.values.product.quantity) {
      formik.setFieldValue(
        "product.total",
        formik.values.product.purchasePrice * formik.values.product.quantity -
          formik.values.product.discount ||
          0 - formik.values.product.saleDiscount ||
          0
      );
    }
  }, [
    formik.values.product.salePrice,
    formik.values.product.quantity,
    formik.values.product.discount,
  ]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    console.log(formik.values.allCustomizationProducts);
    if (allCustomizationProduct && allCustomizationProduct.length > 0) {
      const allCustomizationProducts = allCustomizationProduct.map(
        (product) => ({
          type: product.type,
          id: product.id,
        })
      );

      formik.setValues({
        ...formik.values,
        allCustomizationProducts: allCustomizationProducts,
      });
    }
  }, [allCustomizationProduct]);

  useEffect(() => {
    if (
      formik?.values?.product?.product &&
      formik?.values?.products?.length > 0
    ) {
      const pro = formik?.values?.products.find(
        (pro) => pro.product === formik?.values?.product?.product
      );

      if (pro) {
        setCurrentProductQuantity(currentProductQuantity - pro.quantity);
      }
    }
  }, [slectedProductQuantity]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-4">
      {saleId && formik.values.saleStatus !== "refund" &&  (
        <TabMenu
          model={items}
          key={activeIndex}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          pt={{
            root: { className: "!overflow-hidden" },
            menu: {
              className: "!bg-transparent !inline-flex mb-4 ",
            },
            action: { className: "!bg-transparent" },
            label: { className: "!text-primary" },
            icon: { className: "!text-primary" },
          }}
        />
      )}
      {activeIndex === 0 && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                pointerEvents: isPosted && user.role_id != 1 ? "none" : "auto",
              }}
            >
              <AddSalesForm
                formik={formik}
                isRefund={isRefund}
                setIsRefund={setIsRefund}
                isPosted= {isPosted}
              />

              <div>
                <AddSalesProductForm
                  formik={formik}
                  currentProductQuantity={currentProductQuantity}
                  setCurrentProductQuantity={setCurrentProductQuantity}
                  validateVarient={validateVarient}
                  setValidateVarient={setValidateVarient}
                  editingProduct={editingProduct}
                  setEditingProduct={setEditingProduct}
                  isRefund={isRefund}
                  setSelectedProductQuantity={setSelectedProductQuantity}
                  isPosted = {isPosted}
                />
              </div>

              <div className="my-3">
                <AddSalesProductTable
                  products={formik.values.products}
                  removeProduct={removeProduct}
                  editProduct={editProduct}
                  disabled = {isPosted}
                />
              </div> 

               {formik.values.products.length > 0 ? (
                <div className="my-3">
                  <SalesCalculation formik={formik} />
                </div>
              ) : null}


            </div>
            <div className="mt-16">
              <div className="flex justify-end gap-4">
                {isPosted ? (
                  <>
                    <Button
                      label={"Go Back"}
                      icon="pi pi-arrow-left"
                      className="p-secondary-btn"
                      onClick={() => navigate(-1)}
                      type="button"
                    />
                  </>
                ) : (
                  <>
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-red-btn"
                      type="button"
                      onClick={() => {
                        dispatch({ type: RESET_SPECIFIC_PRODUCT });
                        formik.resetForm();
                        setIsRefund(false);
                      }}
                    />
                    <Button
                      label={"Submit"}
                      icon="pi pi-check"
                      severity="help"
                      className="p-secondary-btn"
                      onClick={handleOpenModal}
                      type="button"
                    />
                    <Button
                      label={"Post"}
                      icon="pi pi-send"
                      className="p-primary-btn"
                      onClick={() => (formik.values.posted = 1)}
                      type="submit"
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="flex justify-center items-center h-screen w-screen"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <ModalContent />
      </Modal>
      {activeIndex === 1 && <AddCustomerSize formik={formik} />}
      {activeIndex === 2 && <CustomizationProduct formik={formik} />}
    </div>
  );
}
