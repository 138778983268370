import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Invoice() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allVarients, products } = useSelector(
    (state) => state.ProductReducer
  );

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    if (specificSale) {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date(specificSale?.created_at);
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    } else {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date();
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [specificSale]);

  const [currentDate, setCurrentDate] = useState("");

  const css = ` .my-data-table-col div  {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
  .my-data-table-col tbody  {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }  
  
  `;

  useEffect(() => {
    if (specificSale) {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date(specificSale?.created_at);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    } else {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    }
  }, [specificSale]);

  const handleProduct = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.title;
    }
    return ""; // Return an empty string if no matching product is found
  };
  // const handletitle = (rowData) => {
  //   const product = products.find((pro) => pro.id === rowData.product);
  //   if (product) {
  //     return product.article_name;
  //   }
  //   return ""; // Return an empty string if no matching product is found
  // };

  const handleVarient = (rowData) => {
    const varient = allVarients.find(
      (vari) => vari.varient_id === rowData.varient
    );

    if (varient) {
      const parsedOptions = JSON.parse(varient.options);
      const optionLabel = parsedOptions.map((option) => {
        return `${option} - ${varient[option]?.value || "N/A"}`;
      });

      return optionLabel.map((op, i) => <div key={i}>{op}</div>);
    }

    return ""; // Return an empty string if no matching product is found
  };

  const [totalQty, setTotalQty] = useState(0);

  //   useEffect(() => {
  //     if (salesDetails) {
  //       let qty = 0;
  //       salesDetails.map((product) => {
  //         qty = qty + product.quantity;
  //       });
  //       setTotalQty(qty);
  //     }
  //   }, [salesDetails]);

  // useEffect(()=>{
  //   const customer = customers.find((customer) => customer.id === sales.customer_id);
  //   if (Object.keys(customer).length !== 0) {
  //     setCustomer(customer)
  //   } else {
  //     setCustomer({});
  //   }
  //   const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //   if (Object.keys(staffs).length !== 0) {
  //     setSelectedStaff(staffs)
  //   } else {
  //     setSelectedStaff({})
  //   }
  // },[])

  //   useEffect(() => {
  //     const customer = customers.find(
  //       (customer) => customer.id === sales.customer_id
  //     );
  //     if (customer && Object.keys(customer).length !== 0) {
  //       setCustomer(customer);
  //     } else {
  //       setCustomer({});
  //     }

  //     const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //     if (staffs && Object.keys(staffs).length !== 0) {
  //       setSelectedStaff(staffs);
  //     } else {
  //       setSelectedStaff({});
  //     }
  //   }, []);

  return (
    // <div className="!w-[60mm]">
    //   <div className="flex justify-center">
    //     <img src="/images/logo2.jpg" className=" w-32 mt-1 mb-2" />
    //   </div>

    //   <div className="w-full mt-1 leading-tight text-xs">
    //     <div className="text-center font-bold">
    //       Rehman Shaheed Road Opp Total Petrol Pump, Gujrat
    //     </div>
    //     <div className="text-center font-bold">
    //       www.tehzeebdesigner.com | 0533-709581
    //     </div>
    //     <div className="text-center font-bold">Whatsapp no | 0321-7542055</div>
    //   </div>

    //   <div className="mt-3">
    //     <div className="text-center text-base font-bold">
    //       {specificSale?.status === "refund" ? "REFUND" : "SALES"} INVOICE
    //       RECEIPT{" "}
    //       {specificSale
    //         ? ` # ${String(specificSale?.id).padStart(5, "0")}`
    //         : null}
    //     </div>
    //   </div>

    //   <div className="w-full mt-3 leading-tight">
    //     {/* <div className="text-center text-sm font-bold mb-2">
    //         {selectedStaff && Object.keys(selectedStaff || {}).length !== 0
    //           ? selectedStaff.name
    //           : ""}
    //         Abdullah Sajjad
    //       </div> */}
    //     <div className="text-center text-xs font-bold">
    //       {specificSale?.customer_name ? specificSale?.customer_name : ""}
    //     </div>
    //     <div className="text-center text-xs font-bold">
    //       {specificSale?.customer_phone ? specificSale?.customer_phone : ""}
    //     </div>
    //     {/* <div className="text-center text-xs font-bold">
    //         {customer ? customer.address : ""}
    //       </div> */}
    //   </div>

    //   <div className="w-full flex justify-center mt-4">
    //     <div
    //       className=" font-bold flex justify-between"
    //       style={{ fontSize: "0.65rem", width: "98%" }}
    //     >
    //       {/* <span className="">ID: {sales ? sales.id : ""}</span> */}
    //       <span>{user.first_name}</span>
    //       <span>{currentDate}</span>
    //       <span>{currentTime}</span>
    //     </div>
    //   </div>
    //   <div style={{ borderBottom: "2px Solid black", width: "98%" }}></div>
    //   <div className="  w-full flex justify-center mt-2 receipt">
    //     <DataTable
    //       className=" text-xs pb-1 my-data-table-col"
    //       value={specificSale?.products}
    //       style={{ width: "50%" }}
    //     >
    //       <Column
    //         header="Product"
    //         className="max-w-[50px] break-words  item-name font-bold text-black"
    //         headerClassName="text-sm font-bold text-black w-[100px]"
    //         body={handleProduct}
    //       />
    //       {/* <Column
    //           header="Article"
    //           className="w-5/12 item-name"
    //           headerClassName="text-sm"
    //           body={handletitle}
    //         /> */}

    //       <Column
    //         header="Qty"
    //         className="w-1/12 item-price font-bold text-black"
    //         headerClassName="text-sm font-bold text-black"
    //         body={(rowData) => rowData.quantity}
    //       />
    //       <Column
    //         header="Price"
    //         className="w-1/12 item-price font-bold text-black"
    //         headerClassName="text-sm font-bold text-black"
    //         body={(rowData) => rowData.purchasePrice.toLocaleString("en-IN")}
    //       />
    //       <Column
    //         body={(rowData) => rowData.discount.toLocaleString("en-IN")}
    //         header="Discount"
    //         className="w-1/12 item-total font-bold text-black"
    //         headerClassName="text-sm font-bold text-black"
    //       />
    //       <Column
    //         body={(rowData) => rowData.total.toLocaleString("en-IN")}
    //         header="Total"
    //         className="w-1/12 item-total font-bold text-black"
    //         headerClassName="text-sm font-bold text-black"
    //       />
    //     </DataTable>
    //     <style>{css}</style>
    //   </div>

    //   <div
    //     className="flex justify-center mx-auto"
    //     style={{ borderBottom: "1px dashed black", width: "98%" }}
    //   >
    //     <div className="flex justify-between w-full text-xs font-bold mx-1 my-2">
    //       <div>
    //         {specificSale?.products ? specificSale?.products.length : 0} x Items
    //         Sold
    //       </div>
    //       <div className="flex gap-2">
    //         <div>SubTotal</div>
    //         <div>
    //           RS{" "}
    //           {specificSale?.total_sales
    //             ? specificSale?.total_sales.toLocaleString("en-IN")
    //             : 0}
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     className="flex justify-center mx-auto"
    //     style={{ borderBottom: "1px dashed black", width: "98%" }}
    //   >
    //     <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
    //       <div>Discount</div>
    //       <div>
    //         RS{" "}
    //         {specificSale?.total_discount
    //           ? specificSale?.total_discount.toLocaleString("en-IN")
    //           : 0}
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     className="flex justify-center mx-auto"
    //     style={{ borderBottom: "1px dashed black", width: "98%" }}
    //   >
    //     <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
    //       <div>VAT ({specificSale?.vat_per ? specificSale?.vat_per : 0}%)</div>
    //       <div>
    //         RS{" "}
    //         {specificSale?.vat_amount
    //           ? specificSale?.vat_amount.toLocaleString("en-IN")
    //           : 0}
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     className="flex justify-center mx-auto"
    //     style={{ borderBottom: "1px dashed black", width: "98%" }}
    //   >
    //     <div className="flex justify-between w-full text-xl font-bold mx-1 my-2">
    //       <div>TOTAL</div>
    //       <div>
    //         RS{" "}
    //         {specificSale?.total_amount
    //           ? specificSale?.total_amount.toLocaleString("en-IN")
    //           : 0}
    //       </div>
    //     </div>
    //   </div>

    //   {/* <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
    //       <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
    //         <div>Cash</div>
    //         <div>
    //           {salesPayment
    //             ? salesPayment.paid_amount.toLocaleString("en-IN")
    //             : ""}
    //         </div>
    //       </div>
    //     </div>

    //     <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
    //       <div className="flex justify-between w-full text-sm font-bold mx-1">
    //         <div>Change</div>
    //         <div>
    //           {salesPayment
    //             ? salesPayment.return_amount.toLocaleString("en-IN")
    //             : ""}
    //         </div>
    //       </div>
    //     </div> */}

    //   <div className="text-center p-4 border-t border-gray-300">
    //     <div className="text-xs mb-2">
    //       Note: After delivery, complains for fabric or stitching will not be
    //       entertained after 3 working days
    //     </div>
    //     <div className="text-lg font-bold mb-2">THANK YOU!</div>
    //     <div className="text-sm">Follow us: Tehzeeb Designer</div>
    //     <div className="flex justify-center gap-4 text-xl mt-4">
    //       <FaWhatsapp />
    //       <FaFacebook />
    //       <FaInstagram />
    //       <FaPinterest />
    //     </div>
    //     <div className="text-xs mt-4 font-bold">
    //       Software provided by Reys Solutions
    //     </div>
    //     <div className="text-xs">Phone: +92 322 5991588</div>
    //   </div>
    // </div>
//     <div className="!w-[60mm] text-[10px] font-mono leading-tight m-0 p-0 pl-[2px]">

//     <div className="flex justify-center">
//       <img src="/images/logo2.jpg" className="w-24 mt-1 mb-1" />
//     </div>
  
//     <div className="text-center font-bold leading-tight">
//       Rehman Shaheed Road Opp Total Petrol Pump, Gujrat  
//       <br /> www.tehzeebdesigner.com | 0533-709581  
//       <br /> Whatsapp | 0321-7542055
//     </div>
  
//     <div className="text-center font-bold text-[7px] my-1">
//       {specificSale?.status === "refund" ? "REFUND" : "SALES"} INVOICE RECEIPT # {specificSale?.id?.toString().padStart(5, "0")}
//     </div>
  
//     {/* <div className="w-full flex justify-between mt-1 font-bold text-[6px]">
//       <span>{user?.first_name}</span>
//       <span>{currentDate}</span>
//       <span>{currentTime}</span>
//     </div> */}
  
//   <div className="w-full flex justify-between mt-1 font-bold text-[6px] pl-[2px] pr-[2px]">
//   <span>{user?.first_name}</span>
//   <span>{currentDate}</span>
//   <span>{currentTime}</span>
// </div>
//     <div style={{ borderBottom: "2px solid black", width: "100%" }}></div>
  
//     {/* TABLE */}
//     <div className="w-full mt-1">
//       <DataTable
//         className="text-[6px] my-data-table-col w-full max-w-full"
//         value={specificSale?.products}
//         style={{ width: "100%" }}
//       >
//         <Column
//           header="Product"
//           className="text-[6px] font-bold text-black w-[55px] truncate"
//           headerClassName="text-[6px] font-bold text-black"
//           body={handleProduct}
//         />
//         <Column
//           header="Qty"
//           className="w-[18px] font-bold text-black"
//           headerClassName="text-[6px] font-bold text-black"
//           body={(rowData) => rowData.quantity}
//         />
//         <Column
//           header="Price"
//           className="w-[25px] font-bold text-black"
//           headerClassName="text-[6px] font-bold text-black"
//           body={(rowData) => rowData.purchasePrice.toLocaleString("en-IN")}
//         />
//         <Column
//           header="Disc"
//           className="w-[18px] font-bold text-black"
//           headerClassName="text-[6px] font-bold text-black"
//           body={(rowData) => rowData.discount.toLocaleString("en-IN")}
//         />
//         <Column
//           header="Total"
//           className="w-[25px] font-bold text-black"
//           headerClassName="text-[6px] font-bold text-black"
//           body={(rowData) => rowData.total.toLocaleString("en-IN")}
//         />
//       </DataTable>
//     </div>
  
//     <div className="flex justify-between text-[6px] font-bold my-1">
//       <span>{specificSale?.products?.length} x Items Sold</span>
//       <span>SubTotal: RS {specificSale?.total_sales?.toLocaleString("en-IN")}</span>
//     </div>
  
//     <div className="flex justify-between text-[6px] font-bold">
//       <span>Discount</span>
//       <span>RS {specificSale?.total_discount?.toLocaleString("en-IN")}</span>
//     </div>
  
//     <div className="flex justify-between text-[6px] font-bold">
//       <span>VAT ({specificSale?.vat_per}%)</span>
//       <span>RS {specificSale?.vat_amount?.toLocaleString("en-IN")}</span>
//     </div>
  
//     <div className="flex justify-between text-[7px] font-bold border-t border-black py-1">
//       <span>TOTAL</span>
//       <span>RS {specificSale?.total_amount?.toLocaleString("en-IN")}</span>
//     </div>
  
//     <div className="text-center text-[6px] border-t border-gray-300 py-2">
//       Note: After delivery, complaints about fabric or stitching will not be entertained after 3 working days.
//       <br />
//       <span className="text-md font-bold">THANK YOU!</span>
//       <br />
//       Follow us: Tehzeeb Designer  
//       <br />
//       <div className="flex justify-center gap-1 text-lg mt-2">
//         <FaWhatsapp />
//         <FaFacebook />
//         <FaInstagram />
//         <FaPinterest />
//       </div>
//       <div className="text-xs mt-1 font-bold">Software provided by Reys Solutions</div>
//       <div className="text-xs">Phone: +92 322 5991588</div>
//     </div>
//   </div>
  

// updated code
<div className="!w-[65mm] text-[10px] font-mono leading-tight m-0 p-0 pl-[10px]">

<div className="flex justify-center">
  <img src="/images/logo2.jpg" className="w-24 mt-1 mb-1" />
</div>

<div className="text-center font-bold leading-tight">
  Rehman Shaheed Road Opp Total Petrol Pump, Gujrat  
  <br /> www.tehzeebdesigner.com | 0533-709581  
  <br /> Whatsapp | 0321-7542055
</div>

<div className="text-center font-bold text-[8px] my-1">
  {specificSale?.status === "refund" ? "REFUND" : "SALES"} INVOICE RECEIPT # {specificSale?.id?.toString().padStart(5, "0")}
</div>

<div className="w-full flex justify-between mt-1 font-bold text-[7px] text-black pl-[2px] pr-[2px]">
  <span>{user?.first_name}</span>
  <span>{currentDate}</span>
  <span>{currentTime}</span>
</div>

<div style={{ borderBottom: "2px solid black", width: "100%" }}></div>

{/* TABLE */}
<div className="w-full mt-1">
  <DataTable
    className="text-[px] font-bold my-data-table-col w-full table-fixed text-black"
    value={specificSale?.products}
    style={{ width: "100%", tableLayout: "fixed" }}
  >
    <Column
      header="Product"
      className="text-[8px] font-extrabold text-black w-[40%] whitespace-normal"
      headerClassName="text-[8x] font-extrabold text-black border-b border-black"
      body={handleProduct}
    />
    <Column
      header="Qty"
      className="w-[5%] text-[8px] font-extrabold text-black"
      headerClassName="w-[5%] text-[8px] font-extrabold text-black border-b border-black"
      body={(rowData) => <span className="font-bold">{rowData.quantity}</span>}
    />
    <Column
      header="Price"
      className="w-[2%] text-[8px] font-extrabold text-black"
      headerClassName="w-[2%] text-[8px] font-extrabold text-black border-b border-black"
      body={(rowData) => <span className="font-bold">{rowData.purchasePrice.toLocaleString("en-IN")}</span>}
    />
    <Column
      header="Disc"
      className="w-[3%] text-[8px] font-extrabold text-black"
      headerClassName="w-[5%] text-[8px] font-extrabold text-black border-b border-black"
      body={(rowData) => <span className="font-bold">{rowData.discount.toLocaleString("en-IN")}</span>}
    />
    <Column
      header="Total"
      className="w-[5%] text-[7px] font-extrabold text-black"
      headerClassName="w-[5%] text-[8px] font-extrabold text-black border-b border-black"
      body={(rowData) => <span className="font-bold">{rowData.total.toLocaleString("en-IN")}</span>}
    />
  </DataTable>
</div>

<div className="flex justify-between text-[7px] font-extrabold text-black my-1">
  <span>{specificSale?.products?.length} x Items Sold</span>
  <span>SubTotal: RS {specificSale?.total_sales?.toLocaleString("en-IN")}</span>
</div>

<div className="flex justify-between text-[7px] font-extrabold text-black">
  <span>Discount</span>
  <span>RS {specificSale?.total_discount?.toLocaleString("en-IN")}</span>
</div>

<div className="flex justify-between text-[7px] font-extrabold text-black">
  <span>VAT ({specificSale?.vat_per}%)</span>
  <span>RS {specificSale?.vat_amount?.toLocaleString("en-IN")}</span>
</div>

<div className="flex justify-between text-[8px] font-extrabold text-black border-t border-black py-1">
  <span>TOTAL</span>
  <span>RS {specificSale?.total_amount?.toLocaleString("en-IN")}</span>
</div>

<div className="text-center text-[7px] text-black border-t border-gray-300 py-2">
  Note: After delivery, complaints about fabric or stitching will not be entertained after 3 working days.
  <br />
  <span className="text-md font-extrabold text-black">THANK YOU!</span>
  <br />
  Follow us: Tehzeeb Designer  
  <br />
  <div className="flex justify-center gap-1 text-lg mt-2 text-black">
    <FaWhatsapp />
    <FaFacebook />
    <FaInstagram />
    <FaPinterest />
  </div>
</div>
</div>

  
  );
}
