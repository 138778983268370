import React, { useRef, useState, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { useDispatch } from "react-redux";
import { getAllVarients } from "../../store/AsyncMethods/ProductMethod";
import { useEffect } from "react";

export default function AddPurchaseProductTable({
  products,
  removeProduct,
  editProduct,
}) {
  const menu = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    products: allProducts,
    specificVarients,
    allVarients,
  } = useSelector((state) => state.ProductReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVarients());
  }, [dispatch]);

  const [showDelDialog, setShowDelDialog] = useState(false);

  const accept = () => {
    removeProduct(selectedItem?.id);
  };

  const reject = () => {
    setShowDelDialog(false);
  };

  let items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        editProduct(selectedItem);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setShowDelDialog(true);
      },
    },
  ];

  const actionBtn = (rowData) => (
    <React.Fragment>
      <div className=" ">
        <i
          className="pi pi-cog text-secondary cursor-pointer"
          onClick={(e) => {
            menu.current.toggle(e);
            setSelectedItem(rowData);
          }}
        ></i>
        <Menu model={items} popup ref={menu} className="p-0 z-50" />
      </div>
    </React.Fragment>
  );

  const purchasePriceBody = (rowData) => {
    return `RS ${rowData.purchasePrice?.toLocaleString("en-IN")}`;
  };

  const salePriceBody = (rowData) => {
    return `RS ${rowData.salePrice?.toLocaleString("en-IN")}`;
  };

  const discountAmountBody = (rowData) => {
    return `RS ${rowData.discount?.toLocaleString("en-IN")}`;
  };

  const totalBody = (rowData) => {
    return `RS ${rowData.total?.toLocaleString("en-IN")}`;
  };

  const productBody = (rowData) => {
    const product = allProducts.find(
      (product) => product.id === rowData.product
    );
    return `${product?.title} - ${product?.article_name}`;
  };

  const designBody = (rowData) => {
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );
    if (varient) return `${varient.design}`;
  };

  const colorBody = (rowData) => {
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );
    if (varient) return `${varient.color}`;
  };

  const varientBody = (rowData) => {
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );
    if (varient) return `${varient.varient_values}`;
  };

  const unitBody = (rowData) => {
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );
    if (varient) return `${varient.options}`;
  };

  const idBody = (rowData, options) => options.rowIndex + 1;

  const articleBody = (rowData) => {
    console.log(allVarients);
    const varient = allVarients.find(
      (varient) => varient.varient_id === rowData.varient
    );
    if (varient) return `${varient.barcode}`;
  };

  // const sortedProducts = useMemo(() => {
  //   return [...products].sort((a, b) => {
  //     const variantA = allVarients.find((v) => v.varient_id === a.varient);
  //     const variantB = allVarients.find((v) => v.varient_id === b.varient);

  //     const [baseA, suffixA = ""] = variantA?.barcode.split("-");
  //     const [baseB, suffixB = ""] = variantB?.barcode.split("-");

  //     const baseComparison = baseA.localeCompare(baseB);
  //     if (baseComparison !== 0) return baseComparison;

  //     return suffixA.localeCompare(suffixB, "suffixB", { numeric: true });
  //   });
  // }, [products, allVarients]);

  const sortedProducts = useMemo(() => {
    return [...products].sort((a, b) => {
      console.log("Sorting Products:", a, b);
  
      const variantA = allVarients.find((v) => v.varient_id === a.varient);
      const variantB = allVarients.find((v) => v.varient_id === b.varient);
  
      console.log("Variants Found:", variantA, variantB);
  
      // Prevent errors if variantA or variantB is undefined
      if (!variantA || !variantB) {
        console.warn("Variant not found!", { a, b, variantA, variantB });
        return 0;
      }
  
      // Prevent errors if barcode is undefined
      if (!variantA.barcode || !variantB.barcode) {
        console.warn("Barcode missing!", { variantA, variantB });
        return 0;
      }
  
      const [baseA, suffixA = ""] = variantA.barcode.split("-");
      const [baseB, suffixB = ""] = variantB.barcode.split("-");
  
      console.log("Parsed Barcodes:", { baseA, suffixA, baseB, suffixB });
  
      const baseComparison = baseA.localeCompare(baseB);
      if (baseComparison !== 0) return baseComparison;
  
      return suffixA.localeCompare(suffixB, undefined, { numeric: true });
    });
  }, [products, allVarients]);
  
  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={sortedProducts}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
          size="small"
        >
          <Column field="id" header="ID #" body={idBody} />
          <Column field="product" header="Product" body={productBody} />
          <Column field="article" header="article" body={articleBody} />
          <Column field="varient" header="varient" body={varientBody} />
          <Column field="color" header="color" body={colorBody} />
          <Column field="design" header="design" body={designBody} />
          <Column field="unit" header="unit" body={unitBody} />
          <Column field="quantity" header="Quantity" />
          <Column
            field="purchasePrice"
            header="Purchase Price"
            body={purchasePriceBody}
          />
          <Column field="salePrice" header="Sale Price" body={salePriceBody} />
          <Column
            field="discount_value"
            header="Discount"
            body={discountAmountBody}
          />
          <Column field="total" header="Total" body={totalBody} />
          <Column header="Action" body={actionBtn} />
        </DataTable>
      </div>

      <ConfirmDialog
        message="Do you want to delete this record?"
        header="Delete confirmation"
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Yes"
        visible={showDelDialog}
        onHide={() => setShowDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />
    </div>
  );
}
