import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import AuthReducer from "./Reducers/AuthReducer";
import CategoryReducer from "./Reducers/CategoryReducer";
import ProductReducer from "./Reducers/ProductReducer";
import UserReducer from "./Reducers/UserReducer";
import SalesReducer from "./Reducers/SalesReducer";
import CustomersReducer from "./Reducers/CustomerReducer";
import DashboardReducer from "./Reducers/DashboardReducer";
import VendorReducer from "./Reducers/VendorReducer";
import SetupReducer from "./Reducers/SetupReducer";
import ScatReducer from "./Reducers/ScatReducer";
import OrderReducer from "./Reducers/OrderReducer";
import PurchaseReducer from "./Reducers/PurchaseReducer";
import FAQsReducer from "./Reducers/FAQsReducer";
import TermsReducer from "./Reducers/TermsReducer";
import PoliciesReducer from "./Reducers/PoliciesReducer";
import CurrencyReducer from "./Reducers/CurrencyReducer";

const rootReducers = combineReducers({
  AuthReducer,
  CategoryReducer,
  ProductReducer,
  UserReducer,
  SalesReducer,
  CustomersReducer,
  DashboardReducer,
  VendorReducer,
  SetupReducer,
  ScatReducer,
  OrderReducer,
  PurchaseReducer,
  FAQsReducer,
  TermsReducer,
  PoliciesReducer,
  CurrencyReducer,
});

const middlewares = [thunk];

const Store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export default Store;
